import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import '../../../index.css'
import { FACEBOOK, GITHUB, LINKEDIN, YOUTUBE } from '../../Constants/index'
import Facebook from 'react-ionicons/lib/LogoFacebook'
import Github from 'react-ionicons/lib/LogoGithub'
import LinkedIn from 'react-ionicons/lib/LogoLinkedin'
import Youtube from 'react-ionicons/lib/LogoYoutube'

const styles = (theme) => ({
  socialList: {
    'list-style-type': 'none',
    padding: 0,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    }
  },
  socialItem: {
    display: 'inline-block',
    paddingRight: 10,
    fontSize: 20
  },
})

class Social extends Component {
  state = {
    fbHover: false,
    gitHover: false,
    linkHover: false,
    ytHover: false,

  }

  render() {
    const { classes } = this.props
    return (
      <ul className={classes.socialList}>
        <li className={classes.socialItem}><a href={GITHUB} target="_blank" rel="noopener noreferrer" onMouseEnter={() => this.setState({gitHover: true})} onMouseLeave={() => this.setState({gitHover: false})}><Github color={this.state.gitHover ? "#78dd60" : "#999999"}/></a></li>
        <li className={classes.socialItem}><a href={LINKEDIN} target="_blank" rel="noopener noreferrer" onMouseEnter={() => this.setState({linkHover: true})} onMouseLeave={() => this.setState({linkHover: false})}><LinkedIn color={this.state.linkHover ? "#78dd60" : "#999999"}/></a></li>
        <li className={classes.socialItem}><a href={YOUTUBE} target="_blank" rel="noopener noreferrer" onMouseEnter={() => this.setState({ytHover: true})} onMouseLeave={() => this.setState({ytHover: false})}><Youtube color={this.state.ytHover ? "#78dd60" : "#999999"}/></a></li>
      </ul>
    )
  }
}

export default withStyles(styles)(Social)
