import React, { Component } from 'react'

class Unauthorized extends Component {
  render() {
    return (
      <div style={{textAlign: 'center'}}>
        <h1>401: Unauthorized</h1>
      </div>
    )
  }
}

export default Unauthorized