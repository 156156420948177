import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Logo from '../../../img/Logo.svg'
import { Link } from "react-scroll"
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import IosMenu from 'react-ionicons/lib/IosMenu'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'block',
    zIndex: 2,
    width: '100%',
  },
  nav: {
    transition: 'ease-in-out 0.25s',
    backgroundColor: '#fff',
    color: '#787878',
  },
  logo: {
    width: '5vw',
    height: 'auto',
    color: '#fff',
    paddingTop: 7,
    paddingLeft: 7,
    paddingRight: 7,
    marginLeft: '3vw',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 13,
      paddingBottom: 6,
      width: '7vw',
    },
  },
  navList: {
    'list-style-type': 'none',
    padding: 0,
    float: 'right',
    margin: 0
  },

  container: {
    float: 'right',
    display: 'inline-block',
    marginTop: '2.6vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4vw',
    },
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#787878',
    transition: 'ease-in-out 0.125s',
    '&:hover': {
      color: '#78dd60'
    },
    marginLeft: 15,
    fontSize: '1.2rem',
    paddingLeft: 5,
    paddingRight: 5
  },
  active: {
    borderBottom: '2px solid #78dd60'
  },
  navBtn: {
    '&:hover': {
      backgroundColor: '#fff'
    },
    width: '7vw',
    height: '7vw'
  },
  drawer: {
    width: '40vw'
  },
  xl: {
    height: 35,
    width: 1,
    marginLeft: 15,
    transform: 'rotate(45deg)',
    backgroundColor: '#787878',
  },
  xr: {
    height: 35,
    width: 1,
    transform: 'rotate(90deg)',
    backgroundColor: '#787878'
  },
  close: {
    '&:hover': {
      opacity: 0.4
    },
    marginLeft: '80%'
  }
})


class Nav extends Component {
  state = {
    scrolled: 0,
    display: false
  }

  constructor(props) {
    super(props)
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  toggleDrawer = (open) => event => {
    if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    this.setState({ display: open })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const scroll = document.body.scrollTop || document.documentElement.scrollTop
    this.setState({ scrolled: scroll })
  }

  render() {
    const { classes } = this.props
    const homeLink = (
      <Link
        activeClass={classes.active}
        to="home"
        smooth={true}
        duration={500}
        spy={true}
        className={classes.link}
        offset={-100}
      >
        Home                  
      </Link>
    )
    const aboutLink = (
      <Link
        activeClass={classes.active}
        to="about"
        smooth={true}
        duration={500}
        spy={true}
        className={classes.link}
        offset={-100}
      >
        About Me                  
      </Link>
    )
    const stackLink = (
      <Link
        activeClass={classes.active}
        to="stack"
        smooth={true}
        duration={500}
        spy={true}
        className={classes.link}
        offset={-140}
      >
        Tech Stack                 
      </Link>
    )
    const portfolioLink = (
      <Link
        activeClass={classes.active}
        to="portfolio"
        smooth={true}
        duration={500}
        spy={true}
        className={classes.link}
        offset={-100}
      >
        Portfolio                
      </Link>
    )
    const contactLink = (
      <Link
        activeClass={classes.active}
        to="contact"
        smooth={true}
        duration={500}
        spy={true}
        className={classes.link}
        offset={-140}
      >
        Contact                
      </Link>
    )

    let NavBar
    if(window.innerWidth > 720) {
      NavBar = (
        <AppBar className={classes.nav} style={{opacity: this.state.scrolled ? '1' : '0'}}>
            <ToolBar style={{display: 'block'}}>
              <div style={{display: 'inline-block'}}>
                <img src={Logo} alt="" className={classes.logo}/>
              </div>
              <div className={classes.container}>
                <ul className={classes.navList}>
                    {homeLink}
                    {aboutLink}
                    {stackLink}
                    {portfolioLink}  
                    {contactLink}
                  </ul>
              </div>
            </ToolBar>
          </AppBar>
      )
    } else {
      NavBar = (
        <AppBar className={classes.nav} style={{opacity: 1 }}>
          <ToolBar style={{display: 'block'}}>
            <div style={{display: 'inline-block'}}>
              <img src={Logo} alt="" className={classes.logo}/>
            </div>
            <div className={classes.container}>
              <Button onClick={this.toggleDrawer(true)} className={classes.navBtn}>
                <IosMenu/>
              </Button>
            </div>
          </ToolBar>
        </AppBar>
      )
    }

    


    return (
      <div className={classes.root}>
        { NavBar }
        <Drawer open={this.state.display} onClose={this.toggleDrawer(false)}>
          <div className={classes.drawer} onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
            <List>
              <ListItem style={{textAlign: 'right'}}>
                <div className={classes.close}>
                  <div className={classes.xl}>
                    <div className={classes.xr}></div>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                {homeLink}
              </ListItem>
              <ListItem>
                {aboutLink}
              </ListItem>
              <ListItem>
                {stackLink}
              </ListItem>
              <ListItem>
                {portfolioLink} 
              </ListItem>
              <ListItem>
                {contactLink}
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(Nav)