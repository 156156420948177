import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import '../../../index.css'
import axios from 'axios'

const styles = (theme) => ({
  contact: {
    marginTop: 40
  },
  title: {
    margin: 0,
    marginBottom: 15,
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.1,
    letterSpacing: 4,
    textTransform: 'uppercase',
    color: '#363636',    
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      marginBottom: 0
    },
  },
  contactForm: {
    marginTop: 16
  },
  contactBtn: {
    transition: 'ease-in-out 0.25s',
    textTransform: 'uppercase',
    width: 'calc(100% + 15px)',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    '&:focus': {
      'box-shadow': '0 0 0 0.2rem rgba(40,167,69,.5)',
      border: '1px solid #28a745'
    },
    '&:hover': {
      'box-shadow': '0 0 0 0.2rem rgba(40,167,69,.5)',
      border: '1px solid #28a745'
    },
    fontSize: 12,
    height: 50,
    lineHeight: '50px',
    padding: '0 30px',
    marginBottom: 30
  },
  formControl: {
    backgroundColor: 'transparent',
    border: '1px solid #999',
    borderRadius: 0,
    color: '#363636',
    fontSize: 12,
    fontWeight: 500,
    height: 50,
    letterSpacing: '0.05rem',
    paddingLeft: 10,
    margin: '0 0 25px 0',
    lineHeight: '15px',
    textTransform: 'uppercase',
    width: '100%',
    fontFamily: 'Arial',
  },
  inputControl: {
    backgroundColor: 'transparent',
    border: '1px solid #999',
    borderRadius: 0,
    color: '#363636',
    fontSize: 12,
    fontWeight: 500,
    height: 50,
    letterSpacing: '0.05rem',
    paddingLeft: 10,
    margin: '0 0 20px 0',
    lineHeight: '15px',
    textTransform: 'uppercase',
    width: '100%',
    fontFamily: 'Arial',
  },
  formInput: {
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 30
    },
  },
  contactList: {
    'list-style-type': 'none',
    paddingLeft: 0,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20
    },
  },
  contactItem: {
    marginBottom: 10,
  },
  contactInfo: {
    color: '#999',
    fontFamily: 'Poppins-Light'
  }
})

class Contact extends Component {
  state = {
    name: '',
    email: '',
    reason: '',
    message: ''
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const data = this.state
    axios.post(process.env.REACT_APP_MAIL_SERVER + '/send', { data })
      .then(res => {
        var result = res.data.status
        if(result === 'success') {
          alert("Your message was sent successfully! I'll get back to you shortly!")
          this.resetForm()
        } else if (result === 'error') {
          alert("An error occured. Try sending the message again!")
        } else {
          alert("An unknow error occured! Please try again later.")
          this.resetForm()
        }
      })    
  }

  resetForm() {
    this.setState({ 
      name: '',
      email: '',
      reason: '',
      message: '' 
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div id="contact" className={classes.contact}>
        <h1 className={classes.title}>Contact Me</h1>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div className={classes.contactInfo}>
              <ul className={classes.contactList}>
                <li className={classes.contactItem}>
                  (+61) 455 636 999
                </li>
                <li className={classes.contactItem}>
                william@williambelcher.io
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <form id="contact-form" onSubmit={this.handleSubmit} method="POST">
              <Grid container className={classes.contactForm}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formInput}>
                    <input type="text" className={classes.formControl} value={this.state.name} onChange={this.handleChange} placeholder="Name" required="required" name="name"/>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formInput}>
                    <input type="email" className={classes.formControl} aria-describedby="emailHelp" value={this.state.email} onChange={this.handleChange} placeholder="Email" required="required" name="email"/>
                  </div>                  
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.formInput}>
                    <input className={classes.formControl} value={this.state.reason} onChange={this.handleChange} placeholder="Contact reason" required="required" name="reason"/>
                  </div>                  
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.formInput}>
                    <textarea className={classes.inputControl} rows="4" value={this.state.message} onChange={this.handleChange} placeholder="Message for me" required="required" name="message"/>
                  </div>                  
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.formInput}>
                    <button type="submit" className={classes.contactBtn}>Submit</button>
                  </div>  
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        
      </div>
    )
  }
}

export default withStyles(styles)(Contact)