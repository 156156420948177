import React from 'react'
import Grid from '@material-ui/core/Grid'
import Nav from './Nav'
import Footer from './Footer'

const Main = ({ children }) => (
  <div>
    
    <Grid container justify="center" alignItems="center">
      <Nav/>
      <Grid item xs={12} md={10} lg={8}>
        { children }
      </Grid>
    </Grid>
    <Footer/>
  </div>
)

export default Main