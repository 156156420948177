import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import '../../../index.css'
import Social from '../Social'

const styles = (theme) => ({
  footer: {
    color: '#999999',
    textAlign: 'center'
  },
  copyright: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '1.2px',
    fontFamily: 'Poppins-Light'
  }
})

class Footer extends Component {
  state = {
    fbHover: false,
    gitHover: false,
    linkHover: false
  }
  
  render() {
    const { classes } = this.props
    return (
      <div className={classes.footer}>
        <Social/>
        <p className={classes.copyright}>
            © COPYRIGHT WILLIAM@WILLIAMBELCHER.IO
        </p>
      </div>
    )
  }
}

export default withStyles(styles)(Footer)