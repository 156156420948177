import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <div style={{textAlign: 'center'}}>
        <h1>404: Page Not Found</h1>
      </div>
    )
  }
}

export default NotFound