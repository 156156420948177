import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import '../../../index.css'
import { borderBottom } from '@material-ui/system';

const styles = (theme) => ({
  portfolio: {
    marginTop: 40
  },
  title: {
    margin: 0,
    marginBottom: 4,
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.1,
    letterSpacing: 4,
    textTransform: 'uppercase',
    color: '#363636',    
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    },
  },
  stats: {
    marginTop: 0,
    marginBottom: '0.75rem',
    fontWeight: 200,
    fontFamily: "Raleway-Light",
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    },
  },
  tableHead: {
    marginTop: '10rem',
    fontWeight: 400,
    marginBottom: 0,
    fontSize: '1.3rem',
    color: '#363636',
    borderBottom: '1px solid #363636'
  },
  tableBody: {
    fontWeight: 200,
    fontFamily: "Raleway-Light",
    fontSize: 18,
    lineHeight: 1.3,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    },  
    td: {
      marginRight: 10,
    }
  },
  tableDesc: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  smallDisappear: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  smallWidth: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  repoLink: {
    color: '#363636',
    
  },
  repo: {
    lineHeight: 2
  }
})

class Portfolio extends Component {
  state = {
    numrepos: 0,
    contributions: 0,
    stats: false,
    repos: '',
    recievedrepos: false
  }
  
  componentDidMount() {
    axios.get(process.env.REACT_APP_NET_SERVER + '/github/stats')
      .then(res => {
        if(res.data) this.setState({numrepos: res.data.repoCount, contributions: res.data.contributions, stats: true})
      })
    axios.get(process.env.REACT_APP_NET_SERVER + '/github/repos')
      .then(res => {
        if(res.data) this.setState({repos: res.data, recievedrepos: true})
        console.log(res.data)
      })
  }
  
  render() {
    const { classes } = this.props
    const { contributions, numrepos, stats, recievedrepos, repos } = this.state
    return (
      <div id="portfolio" className={classes.portfolio}>
        {stats ? <h1 className={classes.title}>My Portfolio</h1> : ''}
        {stats ? <h2 className={classes.stats}>I've committed {contributions} times in {numrepos} repositories<span className={classes.smallDisappear}>, here are my most recent:</span></h2> : ''}
        {recievedrepos ?  <table frame="void" rules="rows" style={{marginLeft: '10px', marginRight: '10px'}}>
                            <thead className={classes.tableHead}>
                              <tr><td>Title</td><td className={classes.smallDisappear}>Description</td><td>Language</td></tr>
                            </thead>
                            <tbody className={classes.tableBody}>
                              {repos.slice(-4).map((item =>
                                <tr key={item.id} className={classes.repo}><td className={classes.smallWidth}><a href={'https://github.com/Belchy06/' + item.name} className={classes.repoLink} target="_blank">{item.name.replace(/-/g, ' ') }</a></td><td className={classes.tableDesc}>{item.description}</td><td>{item.language}</td></tr>
                              ))}
                            </tbody>
                          </table> : ''}
        <Grid container justify="center" alignItems="center">
          
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Portfolio)