import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import setAuthHeader from './utils/setAuthHeader'

import Main from './components/Layout/Main'
import Home from './components/Pages/Home'
import Login from './components/Pages/Login'
import NotFound from './components/Pages/404'
import Unauthorized from './components/Pages/Unauthorized';

const cookies = new Cookies()
if(cookies.get('jwt')) {
  const currentTime = Date.now() / 1000
  const decode = jwtDecode(cookies.get('jwt'))
  if(currentTime > decode.exp) {
    cookies.remove('jwt')
  } else {
    setAuthHeader(cookies.get('jwt'))
  }
}
class App extends Component {
  

  render() {
    return (
      <div>
        <BrowserRouter>
          <Main>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/login" component={Login}/>
              <Route path="/unauthorized" component={Unauthorized}/>
              <Route component={NotFound}/>
            </Switch>
          </Main>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
