import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import setAuthHeader from '../../utils/setAuthHeader'
import Cookies from 'universal-cookie'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Logo from '../../img/Logo.svg'

const cookies = new Cookies()

const styles = (theme) => ({
  TextField: {
    width: '100%',
    marginBottom: 5
  },
  btnBlock: {
    textAlign: 'center'
  },
  container: {
    marginTop: '30vh',
    height: '58vh',
    paddingLeft: 10,
    paddingRight: 10
  },
  root: {
    flexGrow: 1,
    display: 'block',
    zIndex: 2,
    width: '100%',
  },
  nav: {
    transition: 'ease-in-out 0.25s',
    backgroundColor: '#fff',
    color: '#787878',
  },
  logo: {
    width: '5vw',
    height: 'auto',
    color: '#fff',
    paddingTop: 7,
    paddingLeft: 7,
    paddingRight: 7,
    marginLeft: '3vw',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 13,
      paddingBottom: 6,
      width: '7vw',
    },
  }
})

class Login extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)  
    this.resetForm = this.resetForm.bind(this)
  }

  state = {
    username: '',
    password: ''
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const userData =  {
      username: this.state.username,
      password: this.state.password
    }
    axios.post(process.env.REACT_APP_NET_SERVER + '/users/authenticate', userData)
      .then(res => {
        const { token } = res.data
        cookies.set('jwt', token, { path: '/', domain: '.williambelcher.io' })
        setAuthHeader(token)
        if(token) {
          alert("Successfully logged in as " + this.state.username)
          this.resetForm()
        } else {
          alert("The credentials you entered were incorrect")
        }
      })
  }

  resetForm() {
    this.setState({username: '', password: ''})
  }


  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AppBar className={classes.nav}>
          <ToolBar style={{display: 'block'}}>
            <div style={{display: 'inline-block'}}>
              <a href="https://williambelcher.io">
                <img src={Logo} alt="" className={classes.logo}/>
              </a>
            </div>
          </ToolBar>
        </AppBar>
        <form onSubmit={this.handleSubmit} className={classes.container}>
          <TextField 
            label = "Username"
            className={classes.TextField}
            value = {this.state.username}
            onChange = {this.handleChange}
            name = "username"
          />
          <TextField 
            label = "Password"
            type = "password"
            className={classes.TextField}
            value = {this.state.password}
            onChange = {this.handleChange}
            name = "password"
          />
          <div className={classes.btnBlock}>
            <Button variant="outlined" type="submit">
              Login
            </Button>
          </div> 
        </form> 
      </div>
    )
  }
}

export default withStyles(styles)(Login)