import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { SPEED } from '../../Constants/index'

const style = {
  canvas: {
    background: '#232323',
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -10
  },
}

class Header extends Component {
  componentDidMount() {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext("2d")

    function initCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    initCanvas();
    
    var stars = [],
        FPS = 30,     // Animation FPS
        x = ((window.innerWidth > 720) ? 100 : 15),      // Number of points
        threshold = 200; // Distance for points to connect
        
    for(let i = 0; i < x; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1 + 1,
        vx: Math.floor(Math.random() * SPEED) - (SPEED / 2),
        vy: Math.floor(Math.random() * SPEED) - (SPEED / 2)
      });
    }

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "lighter";
    
      for(let i = 0, x = stars.length; i < x; i++) {
        var star = stars[i];
    
        ctx.fillStyle = "#fff";
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0 , 2 * Math.PI);
        ctx.fill();
        ctx.fillStyle = 'black';
        ctx.stroke();
      }
    
      ctx.beginPath();
      for(let i = 0, x = stars.length; i < x; i++) {
        var starI = stars[i];
        ctx.moveTo(starI.x, starI.y);
        for(let j = 0, x = stars.length; j < x; j++) {
          var starII = stars[j];
          if(distance(starI, starII) < threshold) {
            ctx.lineTo(starII.x, starII.y);
          }
        }
      }
      ctx.lineWidth = 0.05;
      ctx.strokeStyle = 'white';
      ctx.stroke();
    }

    function distance(point1, point2) {
      var xs = 0;
      var ys = 0;
    
      xs = point2.x - point1.x;
      xs = xs * xs;
    
      ys = point2.y - point1.y;
      ys = ys * ys;
    
      return Math.sqrt(xs + ys);
    }

    function update() {
      for(var i = 0, x = stars.length; i < x; i++) {
        var s = stars[i];
    
        s.x += s.vx / FPS;
        s.y += s.vy / FPS;
    
        if(s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
        if(s.y < 0 || s.y > canvas.width) s.vy = -s.vy;
      }
    }

    function tick() {
      draw();
      update();
      requestAnimationFrame(tick);
    }
    
    tick();
  }

  render() {
    const { classes } = this.props
    return (
      <div className={ classes.bgImage }>
        <canvas ref="canvas" className={ classes.canvas }></canvas>
      </div>
    )
  }
}

export default withStyles(style)(Header)