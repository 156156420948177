import React, { Component } from 'react'
import Header from '../Layout/Header'
import About from '../Layout/About'
import Portfolio from '../Layout/Portfolio'
import Stack from '../Layout/Stack'
import Contact from '../Layout/Contact'


class Home extends Component {
  render() {
    return (
      <div>
        <Header/>
        <About/>
        <Stack/>
        <Portfolio/>
        <Contact/>
      </div>
    )
  }
}

export default Home