import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Profile from '../../../img/Profile.jpg'
import '../../../index.css'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Globe from 'react-ionicons/lib/IosGlobe'
import Code from 'react-ionicons/lib/MdCode'
import Laptop from 'react-ionicons/lib/MdLaptop'
import Desktop from 'react-ionicons/lib/MdDesktop'
import Browsers from 'react-ionicons/lib/IosBrowsers'
import Git from 'react-ionicons/lib/MdGitPullRequest'

const styles = (theme) => ({
  about: {
    paddingTop: 60,
    width: '100%'
  },
  img: {
    borderRadius: '50%',
    width: '70%',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '65%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    }
  },
  info: {
    color: '#363636',
    lineHeight: '30px',
    fontSize: 20,
    fontFamily: 'Raleway-Light',
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      margin: 10
    },

  },
  carousel: {
    marginTop: 40
  },
  item: {
    textAlign: 'center',
    color: '#363636'
  },
})

class About extends Component {
  render() {
    const { classes } = this.props
    return (
      <div id="about">
        <Grid container justify="center" alignItems="center" className={classes.about}>
          <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
            <img src={Profile} alt="" className={classes.img}/>
          </Grid>
          <Grid item xs={12} md={8}>
            <p className={classes.info}>
              I'm a freelance, software developer based in Cairns, Queensland who loves clean, simple & unique design. I also love to develop web & home automation solutions.
            </p>
          </Grid>
        </Grid>
        <div className={classes.carousel}>
          <OwlCarousel
            loop 
            margin={10}
            autoplay
          >
            <div className={classes.item}><Globe fontSize="44px" color="#363636"/><div>Web Design</div></div>
            <div className={classes.item}><Code fontSize="44px" color="#363636"/><div>Java</div></div>
            <div className={classes.item}><Laptop fontSize="44px" color="#363636"/><div>Embedded Systems</div></div>
            <div className={classes.item}><Desktop fontSize="44px" color="#363636"/><div>C#</div></div>
            <div className={classes.item}><Browsers fontSize="44px" color="#363636"/><div>ASP.NET</div></div>
            <div className={classes.item}><Git fontSize="44px" color="#363636"/><div>GIT</div></div>
          </OwlCarousel>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(About)