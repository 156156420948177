import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import '../../../index.css'
import Background from './Background'
import Arrow from 'react-ionicons/lib/MdNavigate'
import { Link } from "react-scroll"
import Social from '../Social'


const styles = (theme) => ({
  headerContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    height: '100%',
    
  },
  h1: {
    fontFamily: "Poppins-Bold",
    textTransform: 'uppercase',
    fontWeight: 700, 
    fontSize: '50px',
    margin: 0,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    }
  },
  h3: {
    fontWeight: 500,
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 4,
    margin: 0,
    lineHeight: 1.1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    }
  },
  h4: {
    fontSize: '1.3rem',
    fontWeight: 500,
    marginBottom: 10
  },
  headerBottom: {
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -100%)',
  },
  '@keyframes bounce': {
    '0%': { transform: 'translateY(0) rotate(180deg)' },
    '20%': { transform: 'translateY(0) rotate(180deg)' },
    '40%': { transform: 'translateY(-20px) rotate(180deg)' },
    '50%': { transform: 'translateY(0) rotate(180deg)' },
    '60%': { transform: 'translateY(-10px) rotate(180deg)' },
    '80%': { transform: 'translateY(0) rotate(180deg)' },
    '100%': { transform: 'translateY(0) rotate(180deg)' }
  },
  link: {
    textDecoration: 'none',
    color: '#fff'
  },
  button: {
    background: 'none',
    border: 'none',
    animation: '$bounce 2s infinite linear'
  }
})

class Header extends Component {
  state = {
    fbHover: false,
    gitHover: false,
    linkHover: false,
    ytHover: false,

  }

  render() {
    const { classes } = this.props
    return (
      <div style={{height: '100vh'}} id="home">
        <Background/>
        <div className={classes.headerContent}>
          <h1 className={classes.h1}>I am william belcher</h1>
          <h3 className={classes.h3}>DEVELOPER, ELECTRONICS ENTHUSIAST, ENGINEERING STUDENT</h3>
          <Social/>
        </div>
        <div className={classes.headerBottom}>
        <Link
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            className={classes.link}
            offset={-70}
          >
            <h4 className={classes.h4}>DISCOVER MORE</h4>
            <button className={classes.button}><Arrow color="#fff"/></button>                 
          </Link>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Header)