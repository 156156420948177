import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import '../../../index.css'

const styles = (theme) => ({
  title: {
    margin: 0,
    marginBottom: 15,
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.1,
    letterSpacing: 4,
    textTransform: 'uppercase',
    color: '#363636',    
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    },
  },
  item: {
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20
    },
    color: '#363636',
  },
  itemTitle: {
    fontWeight: 400,
    marginBottom: 0,
    fontSize: '1.3rem'
  },
  itemSub: {
    marginTop: 0,
    marginBottom: '0.75rem',
    fontWeight: 200,
    fontFamily: "Raleway-Light"
  },
  itemList: {
    paddingLeft: 20,
    fontFamily: "Raleway-Light"
  },
  bold: {
    fontSize: 20
  }
})

class Stack extends Component {
  render() {
    const { classes } = this.props
    return (
      <div id="stack" style={{marginTop:40}}>
        <h1 className={classes.title}>Software Stack</h1>
        <Grid container justify="center">
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Front End</h2>
              <h3 className={classes.itemSub}>ReactJS</h3>
              <ul className={classes.itemList}>
                <li><p>Single page web apps created using React</p></li>
                <li>Multi-page apps utilise an NGINX reverse proxy</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Backend Web Servers</h2>
              <h3 className={classes.itemSub}>NodeJS + Express</h3>
              <ul className={classes.itemList}>
                <li>Primarily used for simple services that faciliate I/O</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Application Servers</h2>
              <h3 className={classes.itemSub}>.NET</h3>
              <ul className={classes.itemList}>
                <li>Utilised for business logic, executing cron jobs and Web APIs</li>
              </ul>
            </div>            
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Security and Authorization</h2>
              <h3 className={classes.itemSub}>JWT</h3>
              <ul className={classes.itemList}>
                <li><p><span className={classes.bold}>J</span>SON <span className={classes.bold}>W</span>eb <span className={classes.bold}>T</span>okens are assigned by the Authorization Server upon successful login</p></li>
              </ul>
            </div>            
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Database</h2>
              <h3 className={classes.itemSub}>MySQL and MongoDB</h3>
              <ul className={classes.itemList}>
                <li>Single instance DB's backed by Persistent Volume Claims. Deploying a DB Cluster is on the roadmap</li>
              </ul>
            </div>            
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <h2 className={classes.itemTitle}>Deployment</h2>
              <h3 className={classes.itemSub}>Docker + Kubernetes</h3>
              <ul className={classes.itemList}>
                <li><p>Docker containers used for each microservice orchestrated by Kubernetes</p></li>
                <li>NGINX Ingress Controller handles routing between each service</li>
              </ul>
            </div>            
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Stack)